<template>
    <router-view></router-view>
</template>

<script>
    "use strict";

    import 'bootstrap/dist/css/bootstrap.css';

    import axios from 'axios';

    axios.defaults.baseURL = process.env.NODE_ENV === 'development'
            ? '//localhost:8090'
            : '/api';

    export default {};
</script>