<template>
    <div>
        <h1>ECG account</h1>
        <h2>⚿ Password reset</h2>
        <p>Here you can request a new password for your ECG account.</p>

        <div class='mb-5'>
            <div v-if="recentRequestTimestamp" class="alert alert-info" role="alert">
                <p>A reset request has been sent recently at <mark>{{ recentRequestTimestamp }}</mark>.
                    You can resend the request if you haven't got the email.</p>

                <form method="POST" @submit.prevent="recentRequestTimestamp = null">
                    <button class="btn btn-info" type="submit">⟳ Resend</button>
                </form>
            </div>
            <template v-else>
                <div v-if="requestFinished" class="alert alert-success" role="alert">
                    Your request has been sent! You should receive the email soon. 📬
                    Please also check your spam mail folder.
                </div>
                <form v-else method="POST" class="col-md-10 mb-4" @submit.prevent="onSubmit">
                    <fieldset :disabled="requestIsWorking">
                        <div class="row g-3">
                            <div class="col-auto">
                                <label for="uid" class="col-form-label"><strong>ECG account ID
                                        <a href="#faq-ecg-account-id">ℹ️</a>:</strong></label>
                            </div>
                            <div class="col-auto">
                                <div class="input-group">
                                    <input id="uid" type="text" class="form-control" placeholder="firstname.lastname"
                                           aria-label="Recipient's ECG account ID" aria-describedby="button-submit"
                                           v-model="uid" minlength="3" required="true" autocapitalize="none"
                                           :autofocus="'autofocus'">
                                    <button class="btn btn-outline-danger" type="submit" id="button-submit">
                                        <span v-if="!requestIsWorking">⏏ Request</span>
                                        <span v-else>
                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </form>

                <div v-if="requestError" class="alert alert-danger" role="alert">{{ requestError }}</div>
            </template>
        </div>

        <h3 class="text-muted">How it works?</h3>
        <p>
            After submitting your request you'll get an email to your external email address
            <a href="#faq-external-email-address">ℹ️</a>, which is linked to your account. It contains an unique link to
            a web page where you can enter a new password for your account. This unique link will be valid for 48 hours.
        </p>
        <br />
        <h5 class="text-muted" id="faq-ecg-account-id">What is my ECG account id?</h5>
        <p>
            It's the part <strong>before</strong> <mark>@ecogood.org</mark> of your ECG account. Enter the ECG account
            ID in the format <cite>firstname.lastname</cite>. For a functional account like
            <cite>hamburg@ecogood.org</cite> it would be <cite>hamburg</cite>.
        </p>
        <br />
        <h5 class="text-muted" id="faq-external-email-address">What is the external email address?</h5>
        <p>
            It was linked to your ECG account at the time of creation. Normally this is <strong>not</strong> your ECG
            email address as long as you aren't requesting access to a functional account like
            <cite>hamburg@ecogood.org</cite>. In case you want to alter this address please
            <a href="https://wiki.ecogood.org/display/PUBLIC/IT-Support" target="_blank">open a ticket at the IT support</a>.
        </p>
        <br />
        <h5>In case of a problem</h5>
        <p>
            You find <a href="https://wiki.ecogood.org/x/DYQjB" target="_blank"> a detailed explanation of the whole
                password reset process</a> in the ECG Wiki.<br />For further help just
            <a href="https://wiki.ecogood.org/display/PUBLIC/IT-Support" target="_blank">
                open a ticket at the IT support</a>.
        </p>
    </div>
</template>

<script>
    "use strict";

    import axios from 'axios';

    export default {
        data: function () {
            return {
                requestIsWorking: false,
                requestFinished: false,
                uid: this.$route.params.uid,
                recentRequestTimestamp: localStorage.getItem('timestamp'),
                requestError: null
            };
        },
        methods: {
            onSubmit: function () {
                this.requestIsWorking = true;

                let uidEncoded = encodeURIComponent(this.uid);

                axios
                        .post(`password/${uidEncoded}/request-change`)
                        .then((response) => {
                            this.requestFinished = true;
                            this.requestError = null;

                            localStorage.setItem('timestamp', new Date().toLocaleString());
                        }, (error) => {
                            let containsResponse = typeof error.response !== 'undefined';
                            this.requestError = containsResponse && error.response.status === 404
                                    ? "Unknown ECG account ID provided!"
                                    : error.message;
                        })
                        .finally(() => {
                            this.requestIsWorking = false;
                        });
            }
        }
    };
</script>

<style>
    body.container {
        max-width: 800px;
    }
    #button-submit {
        min-width: 100px;
    }
</style>