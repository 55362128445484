"use strict";

import { createRouter, createWebHashHistory } from 'vue-router';

import RequestReset from './RequestReset';
import SetPassword from './SetPassword';

const routes = [
    {name: 'request', path: '/:uid?', component: RequestReset},
    {name: 'set', path: '/set/:token', component: SetPassword, }
];

export default createRouter({
    history: createWebHashHistory(),
    routes
});