<template>
    <div class="progress">
        <div class="progress-bar" :class="passwordClass" role="progressbar" :aria-valuenow="passwordStrength"
             :style="{ width: (passwordStrength*24)+4 + '%' }"
             aria-valuemin="0" aria-valuemax="11"></div>
    </div>
</template>

<script>
    "use strict";

    import zxcvbn from 'zxcvbn';

    let cssClasses = {
        0: 'bg-danger',
        1: 'bg-danger',
        2: 'bg-warning',
        3: 'bg-warning',
        4: 'bg-success'
    };

    let keywords = ['ecg', 'gwö', 'ecogood', 'gemeinwohl', 'gemeinwohlökonomie'];

    export default {
        props: {
            password: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                passwordStrength: 0,
                passwordClass: 'bg-danger',
                passwordWarning: ''
            };
        },
        watch: {
            password: function () {
                let result = zxcvbn(this.password, keywords);

                this.passwordStrength = result.score;
                this.passwordClass = cssClasses[result.score];
                this.passwordWarning = result.feedback.warning;

                this.$emit('score', result.score);
                this.$emit('feedback', result.feedback.warning);
            }
        }
    };
</script>
